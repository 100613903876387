import React, { Component } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Bio from '../components/bio';
import Post from '../components/post';
import { readingEmojis } from '../utils/consts';

class BlogIndex extends Component {
  render() {
    const { data } = this.props;
    const posts = data.allMarkdownRemark.edges;
    return (
      <Layout>
        <SEO
          title="Riner Codes"
          keywords={[`blog`, `react`, 'web', 'development', 'life', 'balance']}
        />
        <Bio />
        <main>
          {posts.map(({ node }, index) => {
            return (
              <Post
                key={node.id}
                node={node}
                readingEmoji={
                  readingEmojis[
                    Math.floor(Math.random() * readingEmojis.length)
                  ]
                }
              />
            );
          })}
        </main>
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          excerpt(pruneLength: 160)
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`;
