export const readingEmojis = [
  '👨‍💻',
  '👨🏻‍💻',
  '👨🏼‍💻',
  '👨🏽‍💻',
  '👨🏾‍💻',
  '👨🏿‍💻',
  '👩‍💻',
  '👩🏻‍💻',
  '👩🏼‍💻',
  '👩🏽‍💻',
  '👩🏾‍💻',
  '👩🏿‍💻',
];
